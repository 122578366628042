import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const TeamGrid = ({ gridItems }) => (
  <ul className="md:col-gap-12 md:grid md:grid-cols-3">
    {gridItems.map((item) => (
      <li className="bg-white mb-4 md:mb-6" key={item.text}>
        <PreviewCompatibleImage imageInfo={item} />
        <div className="ml-8">
          <h3 className="font-serif md:text-4xl mb-2 mt-10 text-2xl text-indigo-900 w-11/12">{item.heading}</h3>
          <p className="md:text-sm mb-10 md:mb-16 text-xs text-gray-600 uppercase w-11/12">{item.text}</p>
          <ul className="flex md:pb-12 pb-8">
            <li className="mr-3">
              <a aria-label={item.heading + '’s GitHub'} className="hover:text-indigo-900 focus:outline-none focus:text-indigo-900 text-gray-600" href={item.github} target="_blank" rel="noopener noreferrer">
                <svg className="fill-current" height="1.2em" width="1.2em">
                  <use xlinkHref="#icon-github"></use>
                </svg>
              </a>
            </li>
            <li>
              <a aria-label={item.heading + '’s LinkedIn'} className="hover:text-indigo-900 focus:outline-none focus:text-indigo-900 text-gray-600" href={item.linkedin} target="_blank" rel="noopener noreferrer">
                <svg className="fill-current" height="1.2em" width="1.2em">
                  <use xlinkHref="#icon-linkedin2"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </li>
    ))}
  </ul>
);

TeamGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      github: PropTypes.string,
      linkedin: PropTypes.string,
    })
  ),
};

export default TeamGrid;