import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const FeatureGrid = ({ gridItems }) => (
  <ul className="md:col-gap-24 md:grid md:grid-cols-3 px-6">
    {gridItems.map((item) => (
      <li className="max-w-sm mb-8 md:mb-24" key={item.heading}>
        <Img fixed={item.image.childImageSharp.fixed} objectFit="contain" />
        <h3 className="font-medium md:text-xl my-3 text-lg text-white">{item.heading}</h3>
        <p className="mb-8 md:leading-relaxed md:text-lg text-indigo-300">{item.text}</p>
      </li>
    ))}
  </ul>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
