import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Team from "../components/Team";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";
import aboutStyles from "./about-page.module.scss";

export const AboutPageTemplate = ({
  heading,
  headingHighlight,
  story,
  value,
  mission,
  team,
  helmet,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <>
      <div className="hero bg-gray-200">
        {helmet || ""}
        <div className="max-w-screen-xl md:pb-64 mx-auto pb-32 pt-8 md:pt-12">
          <h1
            className="font-serif max-w-3xl mb-10 md:leading-tighter md:mb-24 md:text-6xl px-8 text-2xl text-gray-600 md:w-11/12"
          >
            {heading} <span className="text-indigo-800">{headingHighlight}</span>
          </h1>
          <div className="md:flex">
            <div className="lg:w-full mb-12 md:max-w-sm px-8 w-3/4">
              <PreviewCompatibleImage imageInfo={story.image} />
            </div>
            <div className="max-w-3xl mb-10 lg:ml-10 px-8 md:w-11/12">
              <h2 className="mb-4 md:mt-3 md:text-sm text-xs text-gray-600 uppercase">
                {story.heading}
              </h2>
              <PageContent className={aboutStyles.content} content={content} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-indigo-800">
        <div className="max-w-screen-xl mx-auto pt-32 md:pt-0 pb-10">
          <div className="md:pb-20 md:relative">
            <div className="max-w-3xl -mt-64 mb-16 md:absolute md:right-0 px-4 w-full">
              <PreviewCompatibleImage imageInfo={story.secondImage} />
            </div>
          </div>
          <h2
            className="font-serif mb-16 md:mb-20 md:pt-64 md:text-5xl px-6 text-3xl text-white"
          >
            {value.heading} <span className="text-blue-300">{value.headingHighlight}</span>
          </h2>
          <Features gridItems={value.design} />
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-screen-xl md:pb-40 md:pt-32 mx-auto pb-16 pt-12 px-6">
          <h2 className="mb-4 md:text-sm text-xs text-gray-600 uppercase">
            {mission.heading}
          </h2>
          <div className="md:flex md:flex-wrap">
            <div
              className="font-serif md:leading-tight mb-6 md:mr-8 md:text-5xl md:w-1/3 text-2xl text-gray-600 w-2/3"
            >
              {mission.subheading} <span className="text-indigo-800">{mission.subheadingHighlight}</span>
            </div>
            <div
              className="md:leading-loose md:pr-24 md:text-lg md:w-3/5 leading-relaxed text-gray-600 md:text-gray-800 w-10/12"
            >
              {mission.text}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 pb-56" id="team">
        <div className="max-w-screen-xl md:pb-32 md:pt-24 mx-auto pb-20 pt-6 px-6">
          <h2
            className="font-serif md:mb-10 md:text-5xl my-8 text-2xl text-center text-gray-600"
          >
            {team.heading} <span className="text-indigo-800">{team.headingHighlight}</span>
          </h2>
          <Team gridItems={team.member} />
        </div>
      </div>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  headingHighlight: PropTypes.string,
  story: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    heading: PropTypes.string,
    secondImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  value: PropTypes.shape({
    heading: PropTypes.string,
    headingHighlight: PropTypes.string,
    design: PropTypes.array,
  }),
  mission: PropTypes.object,
  team: PropTypes.shape({
    heading: PropTypes.string,
    headingHighlight: PropTypes.string,
    member: PropTypes.array,
  }),
  helmet: PropTypes.object,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        title={post.frontmatter.title}
        heading={post.frontmatter.heading}
        headingHighlight={post.frontmatter.headingHighlight}
        story={post.frontmatter.story}
        value={post.frontmatter.value}
        mission={post.frontmatter.mission}
        team={post.frontmatter.team}
        helmet={
          <Helmet>
            <body className="light-theme" />
            <title>{post.frontmatter.title} | CVision AI</title>
          </Helmet>
        }
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        headingHighlight
        story {
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          secondImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        value {
          heading
          headingHighlight
          design {
            heading
            image {
              childImageSharp {
                fixed(height: 95, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            text
          }
        }
        mission {
          heading
          subheading
          subheadingHighlight
          text
        }
        team {
          heading
          headingHighlight
          member {
            heading
            image {
              childImageSharp {
                fluid(maxWidth: 373, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            github
            linkedin
          }
        }
      }
    }
  }
`;
